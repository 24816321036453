/* eslint-disable no-alert, no-console*/

import React, { useEffect, useRef } from 'react';
import { Window, WindowHeader, Button, WindowContent } from 'react95';
import styled from 'styled-components';

interface Props {
    open: boolean;
    handleClose?: () => void;
    title: string;
    description: string;
    link: string;
    linkedButtons: any;
    meta:any
}

export const WindowDialog: React.FC<Props> = ({
                                                  open,
                                                  handleClose,
                                                  title,
                                                  description,
                                                  link,
                                                  linkedButtons,
                                                  meta
                                              }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                handleClose!();
            }
        };

        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, handleClose]);

    return (
        <>
            {open && (
                <StyledBackground>
                    <StyledModal ref={modalRef}>
                        <StyledWindow>
                            <WindowTitle style={{ background: ' #808080' }}>
                                <FlexWrapper>
                                    <img
                                        style={{
                                            width: "32px",
                                            height: "32px",
                                            objectFit: "scale-down",
                                        }}
                                        src={link}
                                        alt={title}
                                    />
                                    <span>{title}</span>
                                </FlexWrapper>
                                <Button onClick={handleClose}>
                                    <CloseIcon />
                                </Button>
                            </WindowTitle>
                            <WindowContent style={{ backgroundColor: '#fff',
                                maxHeight: '380px',
                                overflowY: 'scroll',
                            }}>
                               <TextWrapper>{description}</TextWrapper>
                                {linkedButtons}
                                {meta}

                            </WindowContent>

                        </StyledWindow>
                    </StyledModal>
                </StyledBackground>
            )}
        </>
    );
};



const StyledModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const CloseIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: -1px;
  margin-top: -1px;
  transform: rotateZ(45deg);
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.materialText};
  }

  &:before {
    height: 100%;
    width: 3px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:after {
    height: 3px;
    width: 100%;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const WindowTitle = styled(WindowHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledWindow = styled(Window)`
  width: 400px;
  
  @media(max-width:400px){
    width: 350px;
  }
  @media(max-width:360px){
    width: 300px;
  }
`
const TextWrapper = styled.figcaption`
  text-align: justify;
font-size: 16px;
  white-space: pre-line;
  
`
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`
const StyledBackground = styled.div`
  z-index: 99999;
  filter: alpha(opacity=70);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: radial-gradient(ellipse at center, transparent 0, transparent 60%, rgba(0, 0, 0, .25) 100%), repeating-linear-gradient(0deg, transparent, transparent 1px, rgba(0, 0, 0, .35) 3px);
  background-size: 100% 100%, 100% 6px;
  -webkit-animation: flicker .3s linear infinite;
  animation: flicker .3s linear infinite;
`


