/* eslint-disable no-alert, no-console*/
import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { styleReset } from 'react95';
import original from 'react95/dist/themes/original';
import {WindowCentered} from "./components/WindowCentered";


const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms-sans-serif';
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms-sans-serif';
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
`;

const App = () => (
    <div>
        <GlobalStyles />
        <ThemeProvider theme={original}>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <  WindowCentered/>
                <ScanLines/>
            </div>
        </ThemeProvider>
    </div>
);

export default App;


const ScanLines = styled.div`
  z-index: 99999;
  opacity: .7;
  filter: alpha(opacity=70);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  background-image: radial-gradient(ellipse at center, transparent 0, transparent 60%, rgba(0, 0, 0, .25) 100%), repeating-linear-gradient(0deg, transparent, transparent 1px, rgba(0, 0, 0, .35) 3px);
  background-size: 100% 100%, 100% 6px;
  -webkit-animation: flicker .3s linear infinite;
  animation: flicker .3s linear infinite;
`