/* eslint-disable no-alert, no-console*/
import {Button, Window, WindowContent, WindowHeader} from "react95";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {WindowDialog} from "./WindowDialog";
import mintIcon from '../assets/icons/w98_key_win_alt.ico';
import contactIcon from '../assets/icons/w2k_write.ico';
import winnersIcon from '../assets/icons/w98_calendar.ico';
import aboutUsIcon from '../assets/icons/w98_help_book_big.ico';
import keyIcon from '../assets/icons/w2k_key_2.ico';
import faqIcon from '../assets/icons/w98_help_book_computer.ico';
import hourGlass from '../assets/icons/w2k_hourglass.ico';
import MetaIcon from '../assets/icons/w2k_lock.ico';
import telegramIcon from '../assets/img/telegramLogo.webp';
import usescript from "../hooks/usescript";



export const WindowCentered = () => {
    const [openDialog, setOpenDialog] = useState(false)
    const [modalTitle, setModalTitle] = useState("");
    const [modalDescription, setModalDescription] = useState("")
    const [modalLink, setModalLink] = useState("")
    const [modalScript, setModalScript] = useState()
    const [metaInfo, setMetaInfo] = useState()
    const [isLoaded, setIsLoaded] = useState(true)
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('');


    const handleOnClickMint = () => {
        let button = document.getElementsByClassName('c-kLhKbD')[0] as HTMLButtonElement;
        button.click();
    }

    const navigateToLink = () => {
        //window.location.href = 'https://debank.com/profile/0xbdb7819a671b83e04800a98e00294a67d7d2ec7a';
        window.open('https://debank.com/profile/0xbdb7819a671b83e04800a98e00294a67d7d2ec7a', '_blank');
    };
    const navigateToTelegramLink = () => {
        // window.location.href = 'https://debank.com/profile/0xbdb7819a671b83e04800a98e00294a67d7d2ec7a';
        window.open('https://t.me/+PDBB_J6kBzVmYmM5', '_blank');
    };

    const WindowInformation = [
        {
            title: "Mint",
            src: mintIcon,
            description: "01/01/2024 12 pm UTC \n First raffle 1 week later after full mint",
            disabled: isLoaded,
            meta:
                <Button disabled={buttonDisabled} onClick={() => handleOnClickMint()}>
                    {buttonText}
                </Button>
        },
        
        {
            title: "Contact",
            src: contactIcon,
            disabled: false,
            linkForAnotherWebsite:
                <Flexbox>
                    <CustomStyledIconButton onClick={navigateToLink}>
                        <StyledImg src={hourGlass} alt={'linked image'}/>
                    </CustomStyledIconButton>
                    <CustomStyledIconButton onClick={navigateToTelegramLink}>
                        <StyledImg src={telegramIcon} style={{filter: 'grayscale(10%) brightness(70%)'}}
                                   alt={'linked image'}/>
                    </CustomStyledIconButton>
                </Flexbox>

        },
        {
            title: "FAQ",
            description: "What is Main pool? \n\n Main pool is money collected from mint as well as reinvestment. The Main pool is supposed to grow continuously in order to stimulate demand and weekly rewards.\n--------------------------------------------\n What does the Main pool consist of? \n\n Main pool will consist 100% of deflationary coins such as eth, cake, possible BTC. Due to this in the long term it is the best solution to preserve investments and minimize risks. All funds will be sent to Staking and locked for a very long period of 2 to 4 years to get as much reward growth as possible. \n--------------------------------------------\n Proof of honesty? \n\n Chainlink VRF will be used as proof of the 100% randomness of the winner selection. This is the best way at the moment. \n--------------------------------------------\n Why are NFT tickets going to get more expensive?\n Since the initial ticket price is N$, Main pool = N$10,000 , as Main Pool grows, so will the cost of NFT on Opensea/other markets. This is the basic system for protecting the value of NFT. In which NFT cannot cost less than the base threshold. \n--------------------------------------------\n How do I find out the weekly reward? \n\n Every week on the site will be displayed the amount won and also the list of winners. \n--------------------------------------------\n Will I be able to sell my tickets? \n\n Yes, you will be able to sell your NFT(tickets) for Opensea/Rarible at market price at any time.\n--------------------------------------------\n  ",
            src: faqIcon,
            disabled: false
        },
        {
            title: "About us",
            description: "DeKeys is a project built on investments in long-term deflationary sources of savings. Every week 100 lucky tickets will receive earned income from all investments.\n--------------------------------------------\n Tokenomics \n Total supply : 10000 \n NFT explain : 1 NFT = 1 ticket  \n Every week winner tickets : 100 \n  Reward distributtion : 70% for weekly winners, 20% to increase Main Pool, 10% for buyback $DeCats & usdc \n Buyback $DeCats explain : 10% weekly rewards going to virtuswap LP and VRSW sold for increase Main Pool.",
            src: aboutUsIcon,
            disabled: false
        },
        {
            title: "Wallet",
            description: "in development... ",
            src: MetaIcon,
            disabled: true
        },
        {
            title: "Weekly Winners",
            description: "in development... ",
            src: winnersIcon,
            disabled: true
        },

    ]
    const handleButtonClick = (title: string, description: any, src: string, linkForAnotherWebsite: any, meta: any) => {
        setModalTitle(title);
        setModalDescription(description)
        setModalLink(src)
        setModalScript(linkForAnotherWebsite)
        setOpenDialog(true);
        setMetaInfo(meta)
    };

    usescript('https://launchpad.heymint.xyz/embed.js')

    useEffect(() => {
            const interval = setInterval(() => {
            let button = document.getElementsByClassName('c-kLhKbD')[0] as HTMLButtonElement;

            if (button !== undefined && !button.innerHTML.includes('svg')) {
                setIsLoaded(false);
                clearInterval(interval);
            }
            if (button !== undefined) {
                setButtonText(button.innerHTML)
                setButtonDisabled(button.disabled)
            }
            return ()=> {clearInterval(interval)}
        }, 50);

    }, []);

    return (
        <>

            <StyledWindow>
                <StyledWindowHeader>
                    <img src={keyIcon} style={{height: '26px'}} alt={"key-icon"}/>
                    <div style={{color: '#BCBCBC'}}>
                        DeKeys | Get your luck
                    </div>
                </StyledWindowHeader>
                <WindowContent style={{
                    padding: '1rem',
                    marginRight: '2px',
                    backgroundColor: '#fff',
                    display: "flex",
                    flexDirection: "column",
                    gap: '48px'
                }}>
                    <GridBox>
                        {WindowInformation.map((button) => (
                            <CustomStyledButton
                                key={button.title}
                                onClick={() => handleButtonClick(button.title, button?.description, button?.src, button?.linkForAnotherWebsite, button?.meta)}
                                disabled={button.disabled}
                            >
                                <StyledImg src={button.src} alt={button.title}/>
                                <figcaption>{button.title}</figcaption>
                            </CustomStyledButton>
                        ))}
                    </GridBox>
                </WindowContent>
            </StyledWindow>
            {openDialog && (
                <WindowDialog
                    open={openDialog}
                    handleClose={() => setOpenDialog(false)}
                    title={modalTitle}
                    description={modalDescription}
                    link={modalLink}
                    linkedButtons={modalScript}
                    meta={metaInfo}
                />
            )}
        </>
    )
}

const CustomStyledButton = styled(Button)`
  touch-action: none !important;
  width: 130px;
  height: 100px;
  margin: 0;
  display: inline-block;
  transform: translate(0px, 0px);
  color: rgb(1, 6, 1);
  font-size: 16px;
  font-weight: 600;
  background-color: #C0C0C0;

  &&:not(:disabled) {
    cursor: url(data:image/gif;base64,R0lGODlhEAAQAKEBAAAAAPj8+Pj8+Pj8+CH5BAEKAAIALAAAAAAQABAAAAItjI9pwO2+ngRBhPmqxU1v7n2gQF4co5knJa6R26pQ2bUsfYvhFW69QQqWhKQCADs=), auto;
  }

  &&:before {
    border-left-color: rgb(199, 199, 223);
    border-top-color: rgb(199, 199, 223);
  }
  @media(max-width: 340px){
    width: 100px;
  }
`;
const CustomStyledIconButton = styled(Button)`
  touch-action: none !important;
  width: 80px;
  height: 60px;
  margin: 0;
  display: inline-block;
  transform: translate(0px, 0px);
  color: rgb(1, 6, 1);
  font-size: 16px;
  font-weight: 600;
  background-color: #C0C0C0;
  border-radius: 16px;
`;

const StyledImg = styled.img`
  width: auto;
  height: 48px;
`;

const StyledWindow = styled(Window)`
  background-color: #C0C0C0;
  max-width: 44em;
  margin: 0 auto;
  height: 100%;
  padding: 20px;
  margin: 1rem;
  @media(max-width: 425px){
    width: 100%;
  }
`
const StyledWindowHeader = styled(WindowHeader)`
  background: #808080;
  color: rgb(199, 199, 223);
  display: flex;
  align-items: center;
  gap: 12px;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  
  @media(max-width:425px) {
    grid-template-columns: repeat(2, 1fr);
  
})
`

const Flexbox = styled.div`
  display: flex;
  gap: 20px;
`
