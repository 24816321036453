import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from './App'
import reportWebVitals from "./reportWebVitals";
const originalConsole = { ...console };
console.log = () => {};
console.warn = () => {};
console.error = () => {};
console.info = () => {};
console.debug = () => {};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);


root.render(
    <React.StrictMode>
            <App />
    </React.StrictMode>
);

reportWebVitals();
