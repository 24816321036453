/* eslint-disable no-alert, no-console*/

import { useEffect } from "react";

const useScript = (url: string) => {
    useEffect(() => {
        const existingScript = document.querySelector(`script[src="${url}"]`);

        if (!existingScript) {
            const script = document.createElement("script");

            script.src = url;
            script.defer = true;
            script.setAttribute("data-project-id", "21132");
            script.setAttribute("data-chain", "BSC");

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [url]);
};

export default useScript;